import { FormGroup, HTMLSelect, Icon, InputGroup, Intent, NumericInput, Tag, Tooltip } from '@blueprintjs/core';
import React, { ChangeEvent, FormEvent, useCallback, useState } from 'react';
import { WickCatcherOpportunitySettings } from '../../../shared/interfaces/bot';

export interface OpportunitySettingFormState {
  id: number | undefined;
  side: string | undefined;
  quantity: number | undefined;
  min_target: string;
  max_individual: number | undefined;
  emergency_cancel: string;
  distance: number | undefined;
  debounce: number | undefined;
  tp_delay: number | undefined;
  quota: number | undefined;
  currency: string;
  key: string;
  data: WickCatcherOpportunitySettings | undefined;
}

export function initOpportunitySettingFormData(item: WickCatcherOpportunitySettings | undefined, key: string): OpportunitySettingFormState {
  if (item) {
    return {
      id: item.id,
      side: item.side,
      quantity: item.quantity,
      min_target: String(item.min_target),
      max_individual: item.max_individual,
      emergency_cancel: String(item.emergency_cancel),
      distance: item.distance,
      debounce: item.debounce,
      tp_delay: item.tp_delay,
      quota: item.quota,
      currency: item.currency,
      key: key,
      data: item,
    };
  }

  return {
    id: undefined,
    side: undefined,
    quantity: undefined,
    min_target: '',
    max_individual: undefined,
    emergency_cancel: '',
    distance: undefined,
    debounce: 5000,
    tp_delay: 60000,
    quota: 1,
    currency: 'USD',
    key: key,
    data: undefined,
  };
}

interface Props {
  data: WickCatcherOpportunitySettings | undefined;
  title: string;
  disabled?: boolean;
  onUpdateForm: (value: string | number, fieldName: string, settingId: string) => void;
  onDelete: (key: string) => void;
}

export function OpportunitySettingForm({ data, title, disabled = false, onUpdateForm, onDelete }: Props) {
  const [supportedCurrencies, setSupportedCurrencies] = useState<string[]>(['USD']);
  const initForm: OpportunitySettingFormState = initOpportunitySettingFormData(data, title);
  const [formState, setFormState] = useState(initForm);
  const handleValueChange = (valueAsNumber: number, valueAsString: string, inputElement: HTMLInputElement | null) => {
    if (inputElement) {
      const { name } = inputElement;
      const formatValue = Number(valueAsString.replace(/\D/g, ''));

      setFormState((prevState) => ({
        ...prevState,
        [name]: formatValue,
      }));

      onUpdateForm(formatValue, name, title);
    }
  };

  const handleInputChange = useCallback((event: FormEvent<HTMLInputElement | HTMLSelectElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;

    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    onUpdateForm(value, name, title);
  }, []);

  const handleDelete = useCallback(() => {
    if (!disabled) {
      onDelete(title);
    }
  }, []);

  return (
    <div className="border border-gray-300 rounded-md pt-5 px-5 mb-4 relative">
      <span className="absolute top-0 left-0">
        <Tag minimal={true} intent={!formState.id ? Intent.PRIMARY : Intent.NONE}>
          {title}
        </Tag>
      </span>

      <span className="absolute top-1 right-1 p-0 text-gray-400 hover:text-gray-500 cursor-pointer" onClick={handleDelete}>
        <Icon icon="cross" aria-disabled={disabled}></Icon>
      </span>

      <div className="grid grid-cols-3 gap-3">
        <FormGroup
          label={
            <div className="flex flex-row items-center" style={{ height: '18px' }}>
              <span className="text-xs">Quantity</span>
              <Tooltip content="How much you want to place for an order" placement="top">
                <Icon icon="help" size={10} className="ml-1 mb-1" />
              </Tooltip>
            </div>
          }
          labelFor={formState.id ? `quantity-${formState.id}` : undefined}
        >
          <NumericInput
            id={formState.id ? `quantity-${formState.id}` : undefined}
            name="quantity"
            fill={true}
            value={formState.quantity?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
            onValueChange={handleValueChange}
            buttonPosition="none"
            placeholder="E.g. 500"
          />
        </FormGroup>

        <FormGroup
          label={<span className="text-xs">Minimum %</span>}
          labelFor={formState.id ? `min_target-${formState.id}` : undefined}
          labelInfo=""
          helperText=""
        >
          <InputGroup
            id={formState.id ? `min_target-${formState.id}` : undefined}
            name="min_target"
            fill={true}
            className="w-4/5"
            value={formState.min_target}
            onChange={handleInputChange}
            placeholder="E.g. 0.75"
          />
        </FormGroup>

        <FormGroup
          label={<span className="text-xs">EC %</span>}
          labelFor={formState.id ? `emergency_cancel-${formState.id}` : undefined}
          labelInfo=""
          helperText=""
        >
          <InputGroup
            id={formState.id ? `emergency_cancel-${formState.id}` : undefined}
            name="emergency_cancel"
            fill={true}
            className="w-4/5"
            value={formState.emergency_cancel}
            onChange={handleInputChange}
            placeholder="E.g. 0.5"
          />
        </FormGroup>
      </div>

      <div className="grid grid-cols-3 gap-3">
        <FormGroup
          label={<span className="text-xs">Max Individual</span>}
          labelFor={formState.id ? `max_individual-${formState.id}` : undefined}
          labelInfo=""
          helperText=""
        >
          <NumericInput
            id={formState.id ? `max_individual-${formState.id}` : undefined}
            name="max_individual"
            fill={true}
            className="w-4/5"
            value={formState.max_individual?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
            onValueChange={handleValueChange}
            buttonPosition="none"
            placeholder="E.g. 10000"
          />
        </FormGroup>

        <FormGroup
          label={<span className="text-xs">Distance</span>}
          labelFor={formState.id ? `distance-${formState.id}` : undefined}
          labelInfo=""
          helperText=""
        >
          <NumericInput
            id={formState.id ? `distance-${formState.id}` : undefined}
            name="distance"
            fill={true}
            value={formState.distance?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
            onValueChange={handleValueChange}
            buttonPosition="none"
            placeholder="E.g. 300000"
          />
        </FormGroup>

        <FormGroup
          label={<span className="text-xs">Quota</span>}
          labelFor={formState.id ? `quota-${formState.id}` : undefined}
          labelInfo=""
        >
          <NumericInput
            id={formState.id ? `quota-${formState.id}` : undefined}
            name="quota"
            fill={true}
            value={formState.quota?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
            onValueChange={handleValueChange}
            buttonPosition="none"
            placeholder="E.g. 5000"
          />
        </FormGroup>
      </div>

      <div className="grid grid-cols-3 gap-3">
        <FormGroup
          label={
            <div className="flex flex-row items-center" style={{ height: '18px' }}>
              <span className="text-xs">Debounce</span>
              <Tooltip content="For opportunies to submit/amend/cancel orders (defaults to 5000)" placement="top">
                <Icon icon="help" size={10} className="ml-1 mb-1" />
              </Tooltip>
            </div>
          }
          labelFor={formState.id ? `debounce-${formState.id}` : undefined}
          helperText=""
        >
          <NumericInput
            id={formState.id ? `debounce-${formState.id}` : undefined}
            name="debounce"
            fill={true}
            value={formState.debounce?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
            onValueChange={handleValueChange}
            buttonPosition="none"
            placeholder="E.g. 5000"
          />
        </FormGroup>

        <FormGroup
          labelFor={formState.id ? `tp_delay-${formState.id}` : undefined}
          label={
            <div className="flex flex-row items-center" style={{ height: '18px' }}>
              <span className="text-xs">Take profit debounce</span>
              <Tooltip content="For submitting market making order to exit" placement="top">
                <Icon icon="help" size={10} className="ml-1 mb-1" />
              </Tooltip>
            </div>
          }
        >
          <NumericInput
            id={formState.id ? `tp_delay-${formState.id}` : undefined}
            name="tp_delay"
            fill={true}
            value={formState.tp_delay?.toLocaleString('en-US', { maximumFractionDigits: 10 })}
            onValueChange={handleValueChange}
            buttonPosition="none"
            placeholder="E.g. 60000"
          />
        </FormGroup>

        <FormGroup
          label={
            <div className="flex flex-row items-center" style={{ height: '18px' }}>
              <span className="text-xs">Currency</span>
              <Tooltip content="Which currency you want to use for Quantity, Offset and Max Individual settings?" placement="top">
                <Icon icon="help" size={10} className="ml-1 mb-1" />
              </Tooltip>
            </div>
          }
          labelFor={formState.id ? `currency-${formState.id}` : undefined}
          labelInfo=""
        >
          <HTMLSelect
            disabled={supportedCurrencies.length < 1}
            id={formState.id ? `currency-${formState.id}` : undefined}
            name="currency"
            fill={true}
            className="w-4/5"
            value={formState.currency}
            onChange={handleInputChange}
          >
            <option value="">Choose an currency...</option>
            {supportedCurrencies.map((currency, index) => (
              <option value={currency} key={index}>
                {currency}
              </option>
            ))}
          </HTMLSelect>
        </FormGroup>
      </div>
    </div>
  );
}
