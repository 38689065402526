import Navigation from '../../components/navigation/navigation';
import { Footer } from '../../components/footer/footer';
import Sidebar from '../../components/sidebar/sidebar';

export function LeftSidebarLayout({ children }: any) {
  return (
    <>
      <div className="App-header"></div>

      <div className="App-body h-full">
        <div className="flex flex-col min-h-screen">
          <div className="fixed top-0 left-0 right-0 z-10 flex h-16 items-center justify-between bg-white ml-60 mr-4">
            <Navigation showCancelAllButton={false} showRightSide={false} />
          </div>

          <div className="flex flex-1">
            <Sidebar />

            <main className="flex-1 ml-56 mt-20 min-h-screen z-0">{children}</main>
          </div>
        </div>
      </div>

      <div className="App-footer fixed z-100 bottom-0 left-0 right-0 text-sm">
        <Footer />
      </div>
    </>
  );
}
