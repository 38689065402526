import { Alert, Button, Intent } from '@blueprintjs/core';
import { useCallback, useState } from 'react';

import axios from '../../../shared/custom-axios';
import { AppToaster } from '../../../shared/app-toaster';
import { Bot } from '../../../shared/interfaces/bot';

interface Props {
  bot: Bot;
}

export function BotStopButton({ bot }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleRestart = useCallback(() => {
    setIsLoading(true);

    axios
      .post<any>(`/api/bots/${bot.id}/stop_all_pairs`, {})
      .then(() => {
        setIsLoading(false);
        setShowConfirmDialog(false);

        const message = `All pairs stopped for selected bot`;

        AppToaster.show({ message: message, icon: 'tick', intent: Intent.SUCCESS, timeout: 2000 });
      })
      .catch((error) => {
        setIsLoading(false);

        const message = JSON.stringify(error.response.data);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      });
  }, []);

  return (
    <>
      <Button
        intent={Intent.WARNING}
        outlined={true}
        loading={isLoading}
        onClick={(e) => {
          e.stopPropagation();

          setShowConfirmDialog(true);
        }}
        small={true}
      >
        Stop All
      </Button>

      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Yes, I am sure"
        icon="issue"
        intent={Intent.WARNING}
        isOpen={showConfirmDialog}
        onCancel={() => {
          setShowConfirmDialog(false);
        }}
        onConfirm={() => handleRestart()}
        canOutsideClickCancel={true}
        canEscapeKeyCancel={true}
      >
        <p>
          This will stop all running wick catchers/pairs and cancel all orders on <strong>{bot.name}</strong>
        </p>

        <p>Are you sure?</p>
      </Alert>
    </>
  );
}
