import { Route, Routes } from 'react-router-dom';

import { ContractStreamList } from '../../components/contract-stream/list/list';
import { ContractStreamForm } from '../../components/contract-stream/form/form';
import { LeftSidebarLayout } from '../layout/left-sidebar';

export function ContractStreamsRoutes() {
  return (
    <LeftSidebarLayout>
      <Routes>
        <Route path="/new" element={<ContractStreamForm />} />
        <Route path="/*" element={<ContractStreamList />} />
      </Routes>
    </LeftSidebarLayout>
  );
}
