import _ from 'lodash';
import { Icon, NonIdealState, Radio, RadioGroup } from '@blueprintjs/core';
import { SortingRule, usePagination, useSortBy, useTable, UseTableCellProps } from 'react-table';
import { useEffect } from 'react';

import { TradeFeedDisplayView, TradeFeedOrderFill } from '../../../../shared/interfaces/bot';
import { Pagination } from '../../../common/pagination/pagination';

interface Props {
  columns: any;
  data: any;
  initSortBy?: SortingRule<any>[];
  pageIndex?: number;
  pageSize?: number;
  totalPage?: number;
  totalEntries?: number;
  showPagination?: boolean;
  showCounter?: boolean;
  showColor?: boolean;
  showPartialData?: boolean;
  isLoading: boolean;
  displayView?: TradeFeedDisplayView;
  changeDisplayView?: (view: TradeFeedDisplayView) => void;
  softSelectedItem: TradeFeedOrderFill | null;
  // Item selected by single click
  softSelectItem?: (item: TradeFeedOrderFill | null) => void;
  // Item selected by double click
  hardSelectItem?: (item: TradeFeedOrderFill) => void;
  onChangePage?: (page: number, pageSize: number, sortBy: any) => void;
}

export function TradeFeedListLatestTable({
  isLoading,
  columns,
  data,
  initSortBy,
  pageIndex: initPage,
  pageSize: initPageSize,
  totalPage,
  totalEntries = 0,
  showPagination = false,
  showCounter = true,
  showPartialData,
  showColor,
  displayView,
  changeDisplayView,
  softSelectItem,
  hardSelectItem,
  onChangePage,
}: Props) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: initPage, pageSize: initPageSize, sortBy: initSortBy },
      manualSortBy: true,
      manualPagination: true,
      pageCount: totalPage,
    },
    useSortBy,
    usePagination,
  );

  const handlePageChange = () => {
    if (onChangePage) {
      onChangePage(pageIndex, pageSize, sortBy);
    }
  };

  // pageIndex and pageSize are internal state of this component
  // reflecting current selected page/pageSize of user,
  // changes to these state should trigger outsiders to be in sync
  useEffect(() => {
    handlePageChange();
  }, [pageIndex, pageSize, sortBy]);

  // initPage and initPageSize are states outside of this component,
  // changes made by outsiders should sync to the internal states
  useEffect(() => {
    if (_.isNumber(initPage)) gotoPage(initPage);
  }, [initPage, gotoPage]);

  useEffect(() => {
    if (_.isNumber(initPageSize)) setPageSize(initPageSize);
  }, [initPageSize, setPageSize]);

  return (
    <div>
      {rows.length <= 0 && (
        <div className="mt-5 w-full overflow-auto">
          <NonIdealState
            icon="folder-open"
            title="No items found"
            description="If you keep this window open, as soon as there is one order fill, it would be shown here immediately. For history querying, please go to History tab."
          />
        </div>
      )}

      <div className="mt-2 mb-1 flex justify-between items-center">
        <p className="mt-4 text-md">
          Displaying <strong>{pageIndex * pageSize}</strong> - <strong>{pageIndex * pageSize + data.length} </strong>
          of <strong>{totalEntries}</strong> order fills
        </p>

        <div className="">
          <Pagination
            isLoading={isLoading}
            totalEntries={totalEntries}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageLength={page.length}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </div>

        <div className="text-right">
          <RadioGroup
            inline={true}
            label=""
            name="view_mode"
            onChange={(e) => changeDisplayView && changeDisplayView(e.currentTarget.value as TradeFeedDisplayView)}
            selectedValue={displayView}
          >
            <Radio label="Compact View" value={TradeFeedDisplayView.CompactView} />
            <Radio label="Full View" value={TradeFeedDisplayView.FullView} />
          </RadioGroup>
        </div>
      </div>

      {rows.length > 0 && (
        <div className={`mt-1 overflow-x-auto ${showPartialData ? 'editor-custom-table' : ''}`}>
          <table {...getTableProps()} className="text-xs">
            <thead className="overflow-x-auto">
              {headerGroups.map((headerGroup: any) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: any) => (
                    <th
                      {...column.getHeaderProps([column.getSortByToggleProps()])}
                      className="border border-gray-200 text-gray-700 text-xs px-4 py-2 bg-gray-100"
                    >
                      <span className="inline-block ml-0.5 mt-0.5">
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <Icon icon="caret-down" size={15} />
                          ) : (
                            <Icon icon="caret-up" size={15} />
                          )
                        ) : (
                          ''
                        )}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="text-right">
              {rows.map((row: any, i: number) => {
                prepareRow(row);

                let currentItem: TradeFeedOrderFill = row.original;
                let classNames = 'border px-4 py-2';

                return (
                  <tr
                    {...row.getRowProps()}
                    className={`hover:bg-gray-100 ${showColor ? currentItem.color : ''}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      softSelectItem && softSelectItem(currentItem);
                    }}
                    onDoubleClick={(e) => {
                      e.stopPropagation();
                      hardSelectItem && hardSelectItem(currentItem);
                    }}
                  >
                    {row.cells.map((cell: UseTableCellProps<TradeFeedOrderFill>) => {
                      const nonHedged = !_.isNumber(currentItem.achieved);
                      // Temporarily highlight Percentage cell in red when given order fill did not hedge successfully
                      if (nonHedged && cell.column.id === 'opportunity.percentage') {
                        classNames = `${classNames} bg-red-300`;
                      }

                      return (
                        <td {...cell.getCellProps()} className={classNames}>
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}

      {showPagination ? (
        <div className="flex justify-center mt-5">
          <Pagination
            isLoading={isLoading}
            totalEntries={totalEntries}
            pageCount={pageCount}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            pageLength={page.length}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            gotoPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </div>
      ) : null}
    </div>
  );
}
