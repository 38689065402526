import { Icon, Intent, type Props, Tag } from '@blueprintjs/core';
import type { DateRange } from '@blueprintjs/datetime';
import moment from 'moment-timezone';

const FORMAT = 'LL';
const FORMAT_TIME = 'LL HH:mm:ss.SSS';

interface MomentDateProps extends Props {
  date: Date | null;
  format?: string;
  withTime?: boolean;
}

export const MomentDate = ({ date, withTime = false, format = withTime ? FORMAT_TIME : FORMAT }: MomentDateProps) => {
  const m = moment(date);

  if (m.isValid()) {
    return <Tag intent={Intent.PRIMARY}>{m.format(format)}</Tag>;
  }

  return <Tag minimal={true}>No datetime selected</Tag>;
};

interface MomentDateRangeProps extends Props {
  range: DateRange;
  format?: string;
  withTime?: boolean;
}

export const MomentDateRange = ({
  className,
  range: [start, end],
  withTime = false,
  format = withTime ? FORMAT_TIME : FORMAT,
}: MomentDateRangeProps) => (
  <div className={className}>
    <MomentDate withTime={withTime} date={start} format={format} />

    <Icon icon="arrow-right" className="mx-2" />

    <MomentDate withTime={withTime} date={end} format={format} />
  </div>
);
