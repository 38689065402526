import { Route, Routes } from 'react-router-dom';

import { BotsIndexPage } from '../../pages/bots';
import { BotsShowPage } from '../../pages/bots/show';
import { LeftSidebarWithoutNavLayout } from '../layout/left-sidebar-without-nav';

export function BotsRoutes() {
  return (
    <LeftSidebarWithoutNavLayout>
      <Routes>
        <Route path=":appId" element={<BotsShowPage />} />
        <Route index element={<BotsIndexPage />} />
      </Routes>
    </LeftSidebarWithoutNavLayout>
  );
}
