import white from './white_orders.mp3';
import blue from './blue_orders.mp3';
import green from './green_orders.mp3';
import orange from './orange_orders.mp3';
import criticalError from './critical-error.mp3';

// Credits: https://notificationsounds.com/notification-sounds/suppressed-437
import suppressed from './suppressed-437.mp3';

export const hedgeSounds = {
  blue: new Audio(blue),
  white: new Audio(white),
  green: new Audio(green),
  orange: new Audio(orange),
};

export const critialErrorSound = new Audio(criticalError);

export const wickcatcherSounds = {
  primaryFilled: new Audio(suppressed),
};

export default hedgeSounds;
