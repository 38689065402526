import { useEffect, useState } from 'react';
import { Button, ControlGroup, HTMLSelect, NumericInput } from '@blueprintjs/core';
import _ from 'lodash';

interface PaginationProps {
  isLoading: boolean;
  totalEntries: number;
  pageCount: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: number[];
  pageLength: number;
  pageIndex: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
  gotoPage: (pageIndex: number) => void;
  nextPage: () => void;
  previousPage: () => void;
}

export function Pagination({
  pageCount,
  canPreviousPage,
  canNextPage,
  pageIndex,
  pageSize,
  setPageSize,
  isLoading,
  gotoPage,
  nextPage,
  previousPage,
}: PaginationProps) {
  const [currentPage, setCurrentPage] = useState<number | string>(pageIndex);
  const [maxPage, setMaxPage] = useState<number>(4);

  const handlePageChange = (page: number) => {
    if (page >= maxPage || page <= maxPage - 3) {
      setMaxPage(Math.min(page + 4, pageCount));
    }
    setCurrentPage(page);
    gotoPage(page);
  };

  const handleNextPage = () => {
    const nextPageIndex = +currentPage + 1;
    if (nextPageIndex >= maxPage) {
      setMaxPage(Math.min(nextPageIndex + 4, pageCount));
    }
    setCurrentPage(nextPageIndex);
    nextPage();
  };

  const handlePreviousPage = () => {
    const previousPageIndex = +currentPage - 1;
    if (previousPageIndex <= maxPage - 3) {
      setMaxPage(Math.min(previousPageIndex + 4, pageCount));
    }
    setCurrentPage(previousPageIndex);
    previousPage();
  };

  useEffect(() => {
    setCurrentPage(pageIndex);
  }, [pageIndex]);

  return (
    <>
      <div className="pagination flex items-center justify-items-end">
        <ControlGroup vertical={false}>
          <Button onClick={() => handlePageChange(0)} disabled={!canPreviousPage}>
            {'<<'}
          </Button>

          <Button onClick={() => handlePreviousPage()} disabled={!canPreviousPage}>
            {'<'}
          </Button>

          <Button onClick={() => handleNextPage()} disabled={!canNextPage}>
            {'>'}
          </Button>

          <Button onClick={() => handlePageChange(pageCount - 1)} disabled={!canNextPage}>
            {'>>'}
          </Button>

          <Button disabled={true}>
            Page{' '}
            <strong>
              {pageIndex + 1} of {pageCount}
            </strong>{' '}
          </Button>

          <NumericInput
            value={_.isNumber(currentPage) ? currentPage + 1 : currentPage}
            onValueChange={(valueAsNumber, valueAsString) => {
              if (valueAsString === '') {
                return setCurrentPage(valueAsString);
              }

              const page = valueAsNumber ? valueAsNumber - 1 : 0;
              handlePageChange(page);
            }}
            min={pageCount >= 1 ? 1 : undefined}
            max={pageCount}
          ></NumericInput>

          <HTMLSelect
            fill={true}
            value={pageSize}
            onChange={(e) => {
              setCurrentPage(0);
              setPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50, 100, 200, 500].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </HTMLSelect>
        </ControlGroup>
      </div>
    </>
  );
}
