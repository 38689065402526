import { useState, useEffect } from 'react';

const DEFAULT_COLUMNS = '1';

export function usePageColumns(localStorageKey: string): [number, React.Dispatch<React.SetStateAction<number>>] {
  let pageColumns = localStorage.getItem(localStorageKey) || DEFAULT_COLUMNS;

  const [layoutColumns, setLayoutColumns] = useState(Number(pageColumns));

  useEffect(() => {
    localStorage.setItem(localStorageKey, String(layoutColumns));
  }, [layoutColumns, localStorageKey]);

  return [layoutColumns, setLayoutColumns];
}
