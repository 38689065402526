import { Route, Routes } from 'react-router-dom';

import { TradeFeedPage } from '../../pages/trade_feed/show';
import { LeftSidebarWithoutNavLayout } from '../layout/left-sidebar-without-nav';

export function TradeFeedRoutes() {
  return (
    <LeftSidebarWithoutNavLayout>
      <Routes>
        <Route index element={<TradeFeedPage />} />
      </Routes>
    </LeftSidebarWithoutNavLayout>
  );
}
