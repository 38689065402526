import { Route, Routes } from 'react-router-dom';

import { NetworkForm } from '../../components/network/form/form';
import { NetworkList } from '../../components/network/list/list';
import { LeftSidebarWithoutNavLayout } from '../layout/left-sidebar-without-nav';

export function NetworksRoutes() {
  return (
    <LeftSidebarWithoutNavLayout>
      <Routes>
        <Route path="/new" element={<NetworkForm />} />
        <Route path="/" element={<NetworkList />} />
      </Routes>
    </LeftSidebarWithoutNavLayout>
  );
}
