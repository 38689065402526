import { Route, Routes } from 'react-router-dom';

import { WickCatchersIndexPage } from '../../pages/wick_catchers';
import { LeftSidebarLayout } from '../layout/left-sidebar';

export function WickCatcherRoutes() {
  return (
    <LeftSidebarLayout>
      <Routes>
        <Route path="active" element={<WickCatchersIndexPage />} />
        <Route path="recently_active" element={<WickCatchersIndexPage />} />
        <Route path="recently_created" element={<WickCatchersIndexPage />} />
        <Route index element={<WickCatchersIndexPage />} />
      </Routes>
    </LeftSidebarLayout>
  );
}
