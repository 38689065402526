import React, { useState, useContext, useEffect, useCallback } from 'react';
import _ from 'lodash';
import axios from '../../../../shared/custom-axios';

import { TradeFeedAggregatedItem } from '../../../../shared/interfaces/bot';
import { TimezoneContext } from '../../../../contexts/timezone';
import { serialize } from '../../../../shared/utils';

import { SearchFilter } from '../../list/search-tool/search-tool';
import { SortingRule } from 'react-table';
import { TradeFeedResultsAggregatedListTable } from './table';
import { TradingPairLink } from '../../../trading-pair/link/link';
import { Classes, Tooltip2 } from '@blueprintjs/popover2';
import { Intent, Tag } from '@blueprintjs/core';

const convertHedgeStateFilterValue = (value: string) => {
  switch (value) {
    case 'hedged':
      return 'finished';
    case 'non-hedge':
      return 'failed';
    default:
      return value;
  }
};

const buildSortByParams = (sortBy: SortingRule<any>) => {
  if (!sortBy || !sortBy.id) {
    return 'id:desc';
  }

  switch (sortBy.id) {
    case 'fill_time':
      return `transaction_time:${sortBy.desc ? 'desc' : 'asc'}`;
    default:
      return `${sortBy.id}:${sortBy.desc ? 'desc' : 'asc'}`;
  }
};

// Results returned from Terminal API has a total row in the end of the list
// so we need to move it on top show display
const moveTotalRowOnTop = (items: TradeFeedAggregatedItem[]) => {
  let index = items.findIndex((item: TradeFeedAggregatedItem) => !item.pair_id);
  let totalRow = items[index];

  items.splice(index, 1);

  return [totalRow].concat(items);
};

interface Props {
  filters: SearchFilter[];
}

export function TradeFeedResultsAggregatedList({ filters }: Props) {
  const { dateFormater, timeFormater } = useContext(TimezoneContext);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<TradeFeedAggregatedItem[]>([]);
  const [selectedItem, setSelectedItem] = useState<TradeFeedAggregatedItem | null>(null);
  const [sortBy, setSortBy] = useState<SortingRule<any>>({ id: 'id', desc: true });
  const defaultSorting = [{ id: 'id', desc: true }];

  const [pageSize, setPageSize] = useState(50);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [totalEntries, setTotalEntries] = useState(0);
  let pageIndex = page - 1;

  const fetchAggregateData = useCallback(({ pageIndex, pageSize, searchFilters, sortBy }: any) => {
    // Pagination is not supported yet
    //
    // let path = `/api/trade_feed/order_fills/aggregated?page_size=${pageSize}&page=${
    //   pageIndex + 1
    // }&sort_by=${buildSortByParams(sortBy)}`;

    let path = `/api/trade_feed/order_fills/aggregated?sort_by=${buildSortByParams(sortBy)}`;

    if (searchFilters.length > 0) {
      let filterParams: any = {};
      searchFilters
        .filter((fi: SearchFilter) => fi.field !== 'transaction_time')
        .forEach((f: any) => {
          if (f.field === 'hedge_state') {
            filterParams[f.field] = Object.assign({}, f, { value: convertHedgeStateFilterValue(f.value) });
          } else {
            filterParams[f.field] = f;
          }
        });
      path = `${path}&${serialize({ filters: filterParams })}`;

      const dateFilter = searchFilters.find((f: SearchFilter) => f.field === 'transaction_time');
      if (dateFilter) {
        const dateRanges = dateFilter.value.split('>');
        path = `${path}&start_time=${dateRanges[0]}&end_time=${dateRanges[1]}`;
      }
    }

    axios
      .get<TradeFeedAggregatedItem[]>(path)
      .then((response) => {
        const entries = response.data;

        let items = moveTotalRowOnTop(entries);
        setItems(items);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handlePageChange = useCallback((page: number, pageSize: number, sortBy: any[]) => {
    // Currently, we can only sort by only 1 field
    sortBy = sortBy[0];
    fetchAggregateData({ pageIndex, pageSize, searchFilters: filters, sortBy: sortBy });
  }, []);

  useEffect(() => {
    fetchAggregateData({ pageIndex, pageSize, searchFilters: filters, sortBy: sortBy });
  }, [pageIndex, pageSize, filters, sortBy, fetchAggregateData]);

  const columns = React.useMemo(() => {
    return [
      {
        Header: '',
        id: 'row_index',
        disableSortBy: true,
        accessor: (item: TradeFeedAggregatedItem, rowIndex: number) => {
          if (!item.pair_id) return '';

          return rowIndex;
        },
        width: '20px',
      },
      {
        Header: 'Pair ID',
        id: 'pair_id',
        accessor: (item: TradeFeedAggregatedItem, rowIndex: number) => {
          return item.pair_id;
        },
        width: '20px',
      },
      {
        Header: 'Pair Name',
        id: 'pair_name',
        accessor: (item: TradeFeedAggregatedItem, _rowIndex: number) => {
          if (!item.pair_id) return 'Overall';

          return <TradingPairLink bot_id={item.app_id} id={item.pair_id} name={item.pair_name}></TradingPairLink>;
        },
        width: '150px',
      },
      {
        Header: (
          <>
            <Tag intent={Intent.DANGER} minimal={true}>
              $ Short
            </Tag>
          </>
        ),
        id: 'aggregated_short_volume',
        accessor: (item: TradeFeedAggregatedItem, _rowIndex: number) => {
          return _.round(item.aggregated_short_volume, 0).toLocaleString('en-US', { maximumFractionDigits: 10 });
        },
        width: '80px',
      },
      {
        Header: (
          <Tag intent={Intent.SUCCESS} minimal={true}>
            $ Long
          </Tag>
        ),
        id: 'aggregated_long_volume',
        accessor: (item: TradeFeedAggregatedItem, _rowIndex: number) => {
          return _.round(item.aggregated_long_volume, 0).toLocaleString('en-US', { maximumFractionDigits: 10 });
        },
        width: '80px',
      },
      {
        Header: (
          <>
            <Tag intent={Intent.DANGER} minimal={true}>
              % Short
            </Tag>
          </>
        ),
        id: 'weighted_average_short_achieved',
        accessor: (item: TradeFeedAggregatedItem, _rowIndex: number) => {
          if (!item.weighted_average_short_achieved) {
            return;
          }

          return (
            <strong className="font-normal">
              <Tooltip2
                hoverOpenDelay={420}
                content={<span>{item.weighted_average_short_achieved}</span>}
                placement="top"
                className={Classes.TOOLTIP2_INDICATOR}
              >
                <span>
                  {_.round(item.weighted_average_short_achieved * 100, 3).toLocaleString('en-US', { maximumFractionDigits: 10 })}%
                </span>
              </Tooltip2>
            </strong>
          );
        },
        width: '30px',
      },
      {
        Header: (
          <Tag intent={Intent.SUCCESS} minimal={true}>
            % Long
          </Tag>
        ),
        id: 'weighted_average_long_achieved',
        accessor: (item: TradeFeedAggregatedItem, _rowIndex: number) => {
          if (!item.weighted_average_long_achieved) {
            return;
          }

          return (
            <strong className="font-normal">
              <Tooltip2
                hoverOpenDelay={420}
                content={<span>{item.weighted_average_long_achieved}</span>}
                placement="top"
                className={Classes.TOOLTIP2_INDICATOR}
              >
                <span>{_.round(item.weighted_average_long_achieved * 100, 3).toLocaleString('en-US', { maximumFractionDigits: 10 })}%</span>
              </Tooltip2>
            </strong>
          );
        },
        width: '30px',
      },
      {
        Header: (
          <Tag intent={Intent.NONE} minimal={true}>
            % Overall
          </Tag>
        ),
        id: 'weighted_average_achieved',
        accessor: (item: TradeFeedAggregatedItem, _rowIndex: number) => {
          if (!item.weighted_average_achieved) {
            return;
          }

          return (
            <strong className="font-normal">
              <Tooltip2
                hoverOpenDelay={420}
                content={<span>{item.weighted_average_achieved}</span>}
                placement="top"
                className={Classes.TOOLTIP2_INDICATOR}
              >
                <span>{_.round(item.weighted_average_achieved * 100, 3).toLocaleString('en-US', { maximumFractionDigits: 10 })}%</span>
              </Tooltip2>
            </strong>
          );
        },
        width: '30px',
      },
      {
        Header: (
          <Tag intent={Intent.DANGER} minimal={true}>
            PNL Short
          </Tag>
        ),
        id: 'aggregated_short_pnl',
        accessor: (item: TradeFeedAggregatedItem, _rowIndex: number) => {
          if (!item.aggregated_short_pnl) {
            return;
          }

          if (item.aggregated_short_pnl < 0) {
            return (
              <strong className="text-red-500 uppercase">
                <Tooltip2
                  hoverOpenDelay={420}
                  content={<span>{item.aggregated_short_pnl}</span>}
                  placement="top"
                  className={Classes.TOOLTIP2_INDICATOR}
                >
                  {_.round(item.aggregated_short_pnl, 0).toLocaleString('en-US', { maximumFractionDigits: 10 })}
                </Tooltip2>
              </strong>
            );
          }

          if (item.aggregated_short_pnl > 0) {
            return (
              <strong className="text-green-500 uppercase">
                <Tooltip2
                  hoverOpenDelay={420}
                  content={<span>{item.aggregated_short_pnl}</span>}
                  placement="top"
                  className={Classes.TOOLTIP2_INDICATOR}
                >
                  {_.round(item.aggregated_short_pnl, 0).toLocaleString('en-US', { maximumFractionDigits: 10 })}
                </Tooltip2>
              </strong>
            );
          }

          return (
            <strong className="font-normal">
              <Tooltip2
                hoverOpenDelay={420}
                content={<span>{item.aggregated_short_pnl}</span>}
                placement="top"
                className={Classes.TOOLTIP2_INDICATOR}
              >
                {_.round(item.aggregated_short_pnl, 0).toLocaleString('en-US', { maximumFractionDigits: 10 })}
              </Tooltip2>
            </strong>
          );
        },
        width: '80px',
      },
      {
        Header: (
          <Tag intent={Intent.SUCCESS} minimal={true}>
            PNL Long
          </Tag>
        ),
        id: 'aggregated_long_pnl',
        accessor: (item: TradeFeedAggregatedItem, _rowIndex: number) => {
          if (!item.aggregated_long_pnl) {
            return;
          }

          if (item.aggregated_long_pnl < 0) {
            return (
              <strong className="text-red-500 uppercase">
                <Tooltip2
                  hoverOpenDelay={420}
                  content={<span>{item.aggregated_long_pnl}</span>}
                  placement="top"
                  className={Classes.TOOLTIP2_INDICATOR}
                >
                  {_.round(item.aggregated_long_pnl, 0).toLocaleString('en-US', { maximumFractionDigits: 10 })}
                </Tooltip2>
              </strong>
            );
          }

          if (item.aggregated_long_pnl > 0) {
            return (
              <strong className="text-green-500 uppercase">
                <Tooltip2
                  hoverOpenDelay={420}
                  content={<span>{item.aggregated_long_pnl}</span>}
                  placement="top"
                  className={Classes.TOOLTIP2_INDICATOR}
                >
                  {_.round(item.aggregated_long_pnl, 0).toLocaleString('en-US', { maximumFractionDigits: 10 })}
                </Tooltip2>
              </strong>
            );
          }

          return (
            <strong className="font-normal">
              <Tooltip2
                hoverOpenDelay={420}
                content={<span>{item.aggregated_long_pnl}</span>}
                placement="top"
                className={Classes.TOOLTIP2_INDICATOR}
              >
                {_.round(item.aggregated_long_pnl, 0).toLocaleString('en-US', { maximumFractionDigits: 10 })}
              </Tooltip2>
            </strong>
          );
        },
        width: '80px',
      },
      {
        Header: (
          <Tag intent={Intent.NONE} minimal={true}>
            PNL
          </Tag>
        ),
        id: 'aggregated_pnl',
        accessor: (item: TradeFeedAggregatedItem, _rowIndex: number) => {
          if (!item.aggregated_pnl) {
            return;
          }

          if (item.aggregated_pnl < 0) {
            return (
              <strong className="text-red-500 uppercase">
                <Tooltip2
                  hoverOpenDelay={420}
                  content={<span>{item.aggregated_pnl}</span>}
                  placement="top"
                  className={Classes.TOOLTIP2_INDICATOR}
                >
                  {_.round(item.aggregated_pnl, 0).toLocaleString('en-US', { maximumFractionDigits: 10 })}
                </Tooltip2>
              </strong>
            );
          }

          if (item.aggregated_pnl > 0) {
            return (
              <strong className="text-green-500 uppercase">
                <Tooltip2
                  hoverOpenDelay={420}
                  content={<span>{item.aggregated_pnl}</span>}
                  placement="top"
                  className={Classes.TOOLTIP2_INDICATOR}
                >
                  {_.round(item.aggregated_pnl, 0).toLocaleString('en-US', { maximumFractionDigits: 10 })}
                </Tooltip2>
              </strong>
            );
          }

          return (
            <strong className="font-normal">
              <Tooltip2
                hoverOpenDelay={420}
                content={<span>{item.aggregated_pnl}</span>}
                placement="top"
                className={Classes.TOOLTIP2_INDICATOR}
              >
                {_.round(item.aggregated_pnl, 0).toLocaleString('en-US', { maximumFractionDigits: 10 })}
              </Tooltip2>
            </strong>
          );
        },
        width: '100px',
      },
    ];
  }, [dateFormater, timeFormater]);

  return (
    <>
      <TradeFeedResultsAggregatedListTable
        columns={columns}
        data={items}
        initSortBy={defaultSorting}
        pageIndex={pageIndex}
        pageSize={pageSize}
        totalPage={totalPage}
        totalEntries={totalEntries}
        onChangePage={handlePageChange}
        showPagination={false}
        showCounter={true}
        isLoading={isLoading}
      />
    </>
  );
}
