import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useMatch, useSearchParams } from 'react-router-dom';
import { Alignment, HTMLSelect, Icon, Intent, Navbar, NavbarGroup, Tab, Tabs } from '@blueprintjs/core';

import axios from '../../shared/custom-axios';
import { WickCatcher, WickCatchersSummary } from '../../shared/interfaces/bot';
import { BotsManagerContext } from '../../contexts/bots-manager';
import { WickCatchersList } from '../../components/wick-catcher/list/list';
import { usePageColumns as useLayoutColumns } from '../../components/common/use-layout-columns';
import { AppToaster } from '../../shared/app-toaster';
import { Pagination } from '../../components/common/pagination/pagination';

interface WickCatcherResponse {
  data: WickCatcher;
}

interface WickCatchersListResponse {
  entries: WickCatcher[];
  page_number: number;
  page_size: number;
  total_entries: number;
  total_pages: number;
}

export const WickCatchersIndexPage = () => {
  const { currentBot } = useContext(BotsManagerContext);
  const [items, setItems] = useState<WickCatcher[]>([]);
  const [itemsSummary, setItemsSummary] = useState<WickCatchersSummary | null>();
  const match = useMatch(`/bots/${currentBot?.app_id}/wick_catchers/:subPage`);
  const subPage = match?.params.subPage || 'all';
  const [searchParams, setSearchParams] = useSearchParams();
  const openingItemId = searchParams.get('editing_id');
  const [editingItem, setEditingItem] = useState<WickCatcher | null>(null);
  const [layoutColumns, setLayoutColumns] = useLayoutColumns('wick_catchers_page_columns');
  const supportedColumnOptions = [1, 2, 3, 4, 5];

  let defaultPageSize = searchParams.get('page_size') ? Number(searchParams.get('page_size')) : 50;
  let page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  let pageIndex = page - 1;
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [totalEntries, setTotalEntries] = useState(0);

  const handlePageChange = useCallback((pageIndex: number, pageSize: number) => {
    updateUrl(pageSize, pageIndex);
  }, []);

  const updateUrl = useCallback((pageSize: number, pageIndex: number) => {
    const page = pageIndex + 1;
    searchParams.set('page_size', String(pageSize));
    searchParams.set('page', String(page));

    // Set search params and navigate to new URL
    setSearchParams(searchParams);
  }, []);

  const fetchData = useCallback(
    ({ pageSize, pageIndex, subPage }: any) => {
      let path;

      switch (subPage) {
        case 'active': {
          path = `/api/wick_catchers?bot_id=${currentBot?.id}&status=active&page_size=${pageSize}&page=${pageIndex + 1}`;

          break;
        }

        case 'recently_active': {
          path = `/api/wick_catchers/recently_active?bot_id=${currentBot?.id}&page_size=${pageSize}&page=${pageIndex + 1}`;

          break;
        }

        case 'recently_created': {
          path = `/api/wick_catchers/recently_created?bot_id=${currentBot?.id}&page_size=${pageSize}&page=${pageIndex + 1}`;

          break;
        }

        default: {
          path = `/api/wick_catchers?bot_id=${currentBot?.id}&page_size=${pageSize}&page=${pageIndex + 1}`;

          break;
        }
      }

      axios.get<WickCatchersListResponse>(path).then((response) => {
        const { entries, total_entries, total_pages } = response.data;
        setPageCount(total_pages);
        setTotalEntries(total_entries);

        setItems(entries);
      });
    },
    [currentBot?.id],
  );

  useEffect(() => {
    fetchData({ pageIndex, pageSize, subPage });
  }, [currentBot?.id, fetchData, openingItemId, pageIndex, pageSize, subPage]);

  useEffect(() => {
    if (!currentBot) {
      return;
    }

    let path = `/api/wick_catchers/summary?bot_id=${currentBot?.id}`;

    axios.get<any>(path).then((response) => {
      setItemsSummary(response.data);
    });
  }, [currentBot, subPage, searchParams]);

  useEffect(() => {
    let wickCatcherId = openingItemId;

    if (openingItemId) {
      axios
        .get<WickCatcherResponse>(`/api/wick_catchers/${wickCatcherId}`)
        .then((response) => {
          const newData = response.data.data;

          setEditingItem(newData);
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.data);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        });
    }
  }, [openingItemId]);

  if (!currentBot) {
    return <p>Current bot does not exist</p>;
  }

  return (
    <>
      <div className="fixed top-16 left-56 mt-0.5 ml-4 right-0 mr-4 z-10">
        <Navbar>
          <Navbar.Group>
            <Navbar.Heading className="font-bold">Wick Catchers</Navbar.Heading>
          </Navbar.Group>

          <Navbar.Group>
            <Tabs animate={true} fill={true} selectedTabId={subPage} large={true} className="">
              <Tab
                id="active"
                title={
                  <Link to={`/bots/${currentBot.app_id}/wick_catchers/active`}>
                    {itemsSummary && itemsSummary.active ? (
                      <span>
                        Active <span className="text-gray-400">{itemsSummary.active.total_entries}</span>
                      </span>
                    ) : (
                      <span>
                        Active <span className="text-gray-400">-</span>
                      </span>
                    )}
                  </Link>
                }
              />

              <Tab
                id="recently_active"
                title={
                  <Link to={`/bots/${currentBot.app_id}/wick_catchers/recently_active`}>
                    {itemsSummary && itemsSummary.recently_active ? (
                      <span>
                        Recently Active <span className="text-gray-400">{itemsSummary.recently_active.total_entries}</span>
                      </span>
                    ) : (
                      <span>
                        Recently Active <span className="text-gray-400">0</span>
                      </span>
                    )}
                  </Link>
                }
              />

              <Tab
                id="recently_created"
                title={
                  <Link to={`/bots/${currentBot.app_id}/wick_catchers/recently_created`}>
                    {itemsSummary && itemsSummary.recently_created ? (
                      <span>
                        New <span className="text-gray-400">{itemsSummary.recently_created.total_entries}</span>
                      </span>
                    ) : (
                      <span>
                        New <span className="text-gray-400">0</span>
                      </span>
                    )}
                  </Link>
                }
              />

              <Tab
                id="all"
                title={
                  <Link to={`/bots/${currentBot.app_id}/wick_catchers`}>
                    {itemsSummary && itemsSummary.all ? (
                      <span>
                        All <span className="text-gray-400">{itemsSummary.all.total_entries}</span>
                      </span>
                    ) : (
                      <span>
                        All <span className="text-gray-400">-</span>
                      </span>
                    )}
                  </Link>
                }
              />
            </Tabs>
          </Navbar.Group>

          <NavbarGroup align={Alignment.RIGHT}>
            <Icon icon="grid-view"></Icon>

            <HTMLSelect
              className="ml-3"
              name="exchange_type"
              value={layoutColumns}
              onChange={(e) => setLayoutColumns(+e.currentTarget.value)}
            >
              {supportedColumnOptions.map((columns) => {
                if (columns === 1) {
                  return (
                    <option value={columns} key={columns}>
                      1 column
                    </option>
                  );
                }

                return (
                  <option value={columns} key={columns}>
                    {columns} columns
                  </option>
                );
              })}
            </HTMLSelect>
          </NavbarGroup>
        </Navbar>
      </div>

      <div className="mt-14 flex justify-between">
        <div></div>

        <div className="flex items-center">
          <div className="">
            <Pagination
              isLoading={false}
              totalEntries={totalEntries}
              pageCount={pageCount}
              canPreviousPage={pageIndex - 1 >= 0}
              canNextPage={pageIndex + 1 < pageCount}
              pageOptions={[]}
              pageLength={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPageSize={setPageSize}
              gotoPage={(page: number) => {
                handlePageChange(page, pageSize);
              }}
              nextPage={() => {
                handlePageChange(pageIndex + 1, pageSize);
              }}
              previousPage={() => {
                handlePageChange(pageIndex - 1, pageSize);
              }}
            />
          </div>
        </div>

        <div></div>
      </div>

      <div className="mt-2 flex flex-row">
        <WickCatchersList
          items={items}
          setItems={setItems}
          editingItem={editingItem}
          setEditingItem={setEditingItem}
          layoutColumns={layoutColumns}
        />
      </div>
    </>
  );
};
