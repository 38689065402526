import { Route, Routes } from 'react-router-dom';

import { PrivateStreamList } from '../../components/private-stream/list/list';
import { PrivateStreamForm } from '../../components/private-stream/form/form';
import { LeftSidebarLayout } from '../layout/left-sidebar';

export function PrivateStreamsRoutes() {
  return (
    <LeftSidebarLayout>
      <Routes>
        <Route path="/new" element={<PrivateStreamForm />} />
        {/* <Route path="/:id" element={<PrivateStreamsShowPage />} /> */}
        <Route path="/*" element={<PrivateStreamList />} />
      </Routes>
    </LeftSidebarLayout>
  );
}
