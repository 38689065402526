import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import axios from '../../shared/custom-axios';
import { Tooltip2 } from '@blueprintjs/popover2';
import _ from 'lodash';
import { SortingRule } from 'react-table';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { Classes, Dialog, Drawer, Intent, Tag, useHotkeys } from '@blueprintjs/core';

import {
  EntryOrderFill,
  ExchangeType,
  HedgeColor,
  HedgeState,
  StrategyType,
  TradeFeedAggregatedItem,
  TradeFeedDisplayView,
  TradeFeedHedgeOrder,
  TradeFeedOrderFill,
  TradeFeedOrderFillsListResponse,
  TradingPair,
} from '../../shared/interfaces/bot';
import { TradeFeedListLatest } from '../../components/trade-feed/list/latest/latest';
import { copyToClipboard, countDecimalPlaces, getPercentageOrRatioText, serialize } from '../../shared/utils';
import { TimezoneContext } from '../../contexts/timezone';
import { TradeFeedOrderFillLagTimeDetails } from '../../components/trade-feed/order-fill/lag-time-details/lag-time-details';
import orderService from '../../shared/order-service';
import { TradeFeedOrderFillItem } from '../../components/trade-feed/order-fill/item/item';
import { SearchFilter, TradeFeedSearchTool } from '../../components/trade-feed/list/search-tool/search-tool';
import { TradingPairLink } from '../../components/trading-pair/link/link';
import { TradeFeedResultsAggregatedList } from '../../components/trade-feed/results-aggregated/list/list';

const normalizeHedgeState = (state: string) => {
  switch (state) {
    case 'finished': {
      return { state: 'hedged', color: Intent.SUCCESS };
    }
    case 'failed': {
      return { state: 'non-hedge', color: Intent.DANGER };
    }
    case 'hedging': {
      return { state: 'hedging', color: Intent.PRIMARY };
    }
    case 'retrying': {
      return { state: 'retrying', color: Intent.WARNING };
    }
    default: {
      return { state: 'unknown', color: Intent.NONE };
    }
  }
};

const getFilterFromQueryParams = (location: any) => {
  const search = location.search;
  const params = new URLSearchParams(search);
  const filterString = params.get('filters');
  let filters: SearchFilter[] = [];
  if (filterString) {
    filters = filterString.split(',').map((fs: string) => {
      const vals = fs.split(';');
      return { field: vals[0], op: vals[1], value: vals[2] };
    });
  }
  return filters;
};

const getSortingFromQueryParams = (location: any) => {
  const search = location.search;
  const params = new URLSearchParams(search);
  const sortString = params.get('sort_by');
  if (sortString) {
    const sortArr = sortString.split(':');
    return { id: sortArr[0], desc: sortArr[1] === 'desc' };
  } else {
    return { id: 'id', desc: true };
  }
};

export const TradeFeedPage = () => {
  const { dateFormater, timeFormater } = useContext(TimezoneContext);

  const [isLoadingOrderFills, setIsLoadingOrderFills] = useState(false);
  const [data, setData] = useState<Array<TradeFeedOrderFill>>([]);
  const [displayView, setDisplayView] = useState<TradeFeedDisplayView | undefined>(
    (localStorage.getItem('trade_feed_display_view') as TradeFeedDisplayView.CompactView) || TradeFeedDisplayView.CompactView,
  );
  const [pageCount, setPageCount] = useState<number>(0);
  const [totalEntries, setTotalEntries] = useState<number>(0);
  const [softSelectedItem, setSoftSelectedItem] = useState<TradeFeedOrderFill | null>(null);
  const [hardSelectedItem, setHardSelectedItem] = useState<TradeFeedOrderFill | null>(null);

  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchFilters, setSearchFilters] = useState<SearchFilter[]>(getFilterFromQueryParams(location));
  const [sortBy, setSortBy] = useState<SortingRule<any>>(getSortingFromQueryParams(location));
  const [showAggregatedResultsDialog, setShowAggregatedResultsDialog] = useState<boolean>(false);

  let pageSize = searchParams.get('page_size') ? Number(searchParams.get('page_size')) : 50;
  let page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  let pageIndex = page - 1;

  const handleSearch = (filters: SearchFilter[], force?: boolean) => {
    setSearchFilters(filters);
    updateUrl(pageSize, 0, filters, sortBy);

    if (force) {
      fetchData({ pageIndex, pageSize, searchFilters, sortBy });
    }
  };

  const handleShowAggregatedResults = useCallback(() => {
    setShowAggregatedResultsDialog(true);
  }, []);

  const updateUrl = useCallback((pageSize: number, pageIndex: number, filters: SearchFilter[], sortBy: any) => {
    let filterParams: any = {};
    filters.forEach((f: any) => (filterParams[f.field] = f));
    const filterString = filters.map((f: SearchFilter) => `${f.field};${f.op};${f.value}`).join(',');

    const page = pageIndex + 1;
    searchParams.set('page_size', String(pageSize));
    searchParams.set('page', String(page));
    searchParams.set('sort_by', buildSortByParams(sortBy));

    if (filters.length > 0) {
      searchParams.set('filters', filterString);
    } else {
      if (searchParams.get('filters')) {
        searchParams.delete('filters');
      }
    }

    // Set search params and navigate to new URL
    setSearchParams(searchParams);
  }, []);

  const handlePageChange = (pageIndex: number, pageSize: number, sortList: any[]) => {
    const sortBy = sortList[0];
    updateUrl(pageSize, pageIndex, searchFilters, sortBy);
  };

  useEffect(() => {
    fetchData({ pageIndex, pageSize, searchFilters, sortBy });
  }, [pageIndex, pageSize, searchFilters, sortBy]);

  useEffect(() => {
    localStorage.setItem('trade_feed_display_view', displayView as string);
  }, [displayView]);

  const columns = React.useMemo(() => {
    const supportedColumns = [
      {
        id: 'percentage_or_ratio',
        disableSortBy: true,
        showOnCompactView: true,
        Header: () => {
          return (
            <>
              <p className="m-0">%</p>
              <p className="m-0">Ratio</p>
            </>
          );
        },
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (!order.trading_pair) {
            return null;
          }
          let strategyType = order.trading_pair.strategy_type;

          switch (strategyType) {
            case StrategyType.PairsTrading: {
              return order.opportunity.ratio;
            }

            default: {
              const percentage = order.opportunity.percentage;
              return percentage ? percentage.toFixed(2) + '%' : '-';
            }
          }
        },
      },
      {
        Header: 'Bot',
        id: 'bot',
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <Link to={`/bots/${order.trading_pair.bot?.app_id}/trading_pairs`} className="text-gray-800">
              {order.trading_pair.bot?.name}
            </Link>
          );
        },
      },
      {
        Header: 'Strategy Type',
        disableSortBy: true,
        id: 'strategy_type',
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          switch (order.trading_pair.strategy_type) {
            case StrategyType.PairsTrading: {
              return <span className="font-normal py-1 px-2 bg-yellow-500 text-gray-100 rounded-xl whitespace-pre">Pairs Trading</span>;
            }

            case StrategyType.WickCatcher: {
              return <span className="font-normal py-1 px-2 bg-pink-800 text-gray-100 rounded-xl whitespace-pre">Wick Catcher</span>;
            }

            case StrategyType.NFTMarketMaking: {
              return (
                <span className="font-normal py-1 px-2 bg-white text-red-500 border-2 border-red-400 rounded-xl whitespace-pre">
                  Blur.io
                </span>
              );
            }

            default: {
              return <span className="font-normal py-1 px-2 bg-gray-200 text-gray-800 rounded-xl whitespace-pre">Normal</span>;
            }
          }
        },
      },
      {
        Header: 'Pair',
        id: 'name',
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (!order.trading_pair) {
            return '-';
          }

          return (
            <TradingPairLink
              bot_id={order.trading_pair.bot.app_id}
              id={order.trading_pair.id}
              name={order.trading_pair.name}
            ></TradingPairLink>
          );
        },
      },
      {
        Header: 'Status',
        disableSortBy: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (order.hedge_orders.length === 0) {
            return (
              <Tag key={'unknown'} intent={Intent.NONE} className="font-normal" round={true}>
                unknown
              </Tag>
            );
          }
          const hedgeOrder = order.hedge_orders.sort((o1, o2) => +new Date(o2.init_bot_ts) - +new Date(o1.init_bot_ts))[0];
          const { state, color } = normalizeHedgeState(hedgeOrder.hedge_state);
          return (
            <Tag key={hedgeOrder.hedge_state} className="font-normal" intent={color} round={true}>
              {state}
            </Tag>
          );
        },
      },
      {
        Header: 'Direction',
        id: 'direction',
        disableSortBy: true,
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          let strategyType = order.trading_pair.strategy_type;

          switch (strategyType) {
            case StrategyType.WickCatcher: {
              // NOTE: 'effective_tier' property only exists in entry order fill
              // exit order fill does not have it

              let normalEntryState = (
                <span className="font-normal py-1 px-2 border border-gray-400 text-gray-500 rounded-xl whitespace-pre">Entry</span>
              );

              let highlightedEntryState = (
                <span className="font-normal py-1 px-2 border border-gray-700 bg-gray-700 text-gray-100 rounded-xl whitespace-pre">
                  Entry
                </span>
              );

              let normalExitState = (
                <span className="font-normal py-1 px-2 border border-green-500 text-green-600 rounded-xl whitespace-pre">Exit</span>
              );

              let highlightedExitState = (
                <span className="font-normal py-1 px-2 border border-green-600 bg-green-600 text-gray-100 rounded-xl whitespace-pre">
                  Exit
                </span>
              );

              let unknownState = (
                <span className="font-normal py-1 px-2 border border-gray-600 text-gray-700 rounded-xl whitespace-pre">Unknown</span>
              );

              // Current order fill is an entry
              if (order.opportunity.direction === 'entry') {
                if (order.id === softSelectedItem?.id) {
                  return highlightedEntryState;
                }

                if (softSelectedItem?.opportunity.effective_tier) {
                  let currentInternalTradeId = order.internal_trade_id;
                  let selectedInternalTradeId = softSelectedItem?.internal_trade_id;

                  if (currentInternalTradeId && selectedInternalTradeId && currentInternalTradeId === selectedInternalTradeId) {
                    return highlightedEntryState;
                  }
                } else {
                  let currentInternalTradeId = order.internal_trade_id;

                  let selectedEntryOrderFill = softSelectedItem?.opportunity.entry_order_fill?.find(
                    (entryOrder: EntryOrderFill) => entryOrder.internal_trade_id === currentInternalTradeId,
                  );

                  if (selectedEntryOrderFill) {
                    return highlightedEntryState;
                  }
                }

                return normalEntryState;
              } else if (order.opportunity.direction === 'exit') {
                // Current order fill is an exit

                if (order.id === softSelectedItem?.id) {
                  return highlightedExitState;
                }

                // Current order fill is an exit and selected order fill is an entry
                if (softSelectedItem?.opportunity.effective_tier) {
                  let selectedInternalTradeId = softSelectedItem?.internal_trade_id;
                  const entryOrders = order.opportunity.entry_order_fill;

                  if (
                    entryOrders &&
                    selectedInternalTradeId &&
                    entryOrders.some((entryOrder: EntryOrderFill) => entryOrder.internal_trade_id === selectedInternalTradeId)
                  ) {
                    return highlightedExitState;
                  }
                } else {
                  const entryOrders = order.opportunity.entry_order_fill;
                  const softEntryOrders = softSelectedItem?.opportunity.entry_order_fill;
                  if (
                    Array.isArray(entryOrders) &&
                    Array.isArray(softEntryOrders) &&
                    entryOrders.find((entry: EntryOrderFill) =>
                      softEntryOrders.some((sofEntry: EntryOrderFill) => sofEntry.internal_trade_id === entry.internal_trade_id),
                    )
                  ) {
                    return highlightedExitState;
                  }
                }

                return normalExitState;
              }

              return unknownState;
            }

            default: {
              if (order.opportunity.is_stop_loss) {
                return <span className="font-normal py-1 px-2 border border-gray-400 text-gray-500 rounded-xl whitespace-pre">SL</span>;
              }
              if (order.opportunity.is_flip) {
                return <span className="font-normal py-1 px-2 border border-gray-400 text-gray-500 rounded-xl whitespace-pre">Flip</span>;
              }

              return <span className="font-normal py-1 px-2 border border-gray-400 text-gray-500 rounded-xl whitespace-pre">Norm</span>;
            }
          }
        },
      },
      {
        Header: `Entry/Exits Count`,
        disableSortBy: true,
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (order.opportunity.direction === 'entry') {
            if (!order.wick_catcher_exits_count) {
              return <span className="font-normal py-1 px-2 border bg-yellow-600  text-gray-100 rounded-xl whitespace-pre">0</span>;
            }

            return (
              <span className="font-normal py-1 px-2 border border-gray-400 text-gray-500 rounded-xl whitespace-pre">
                {order.wick_catcher_exits_count}
              </span>
            );
          }

          if (order.opportunity.direction === 'exit') {
            if (!order.wick_catcher_entries_count) {
              return <span className="font-normal py-1 px-2 border bg-yellow-600  text-gray-100 rounded-xl whitespace-pre">0</span>;
            }

            return (
              <span className="font-normal py-1 px-2 border border-gray-400 text-gray-500 rounded-xl whitespace-pre">
                {order.wick_catcher_entries_count}
              </span>
            );
          }
        },
      },
      {
        Header: `Qty`,
        disableSortBy: true,
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          const isWickCatcherExit = order.trading_pair.strategy_type === StrategyType.WickCatcher && order.opportunity.direction === 'exit';

          // Opportunity's Qty for WickCatcher's exit is in base_currency instead of in pair's currency (which is usually in USD)
          if (isWickCatcherExit) {
            return (
              <>
                <span className="block">
                  <span className="font-semibold py-1 px-2 border border-gray-300 text-gray-600 rounded-xl whitespace-pre">
                    {order.opportunity?.quantity}
                  </span>
                </span>

                <span className="block mt-2 text-gray-600 text-xxs">{order.last_fill_volume?.currency}</span>
              </>
            );
          }

          return (
            <>
              <span className="block">
                <span className="font-semibold py-1 px-2 border border-gray-300 text-gray-600 rounded-xl whitespace-pre">
                  {order.opportunity?.quantity}
                </span>
              </span>

              <span className="block mt-2 text-gray-600 text-xxs">{order.trading_pair?.currency}</span>
            </>
          );
        },
      },
      {
        Header: 'Date',
        id: 'transaction_time',
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (!order.fill_tx_ts) {
            return '';
          }

          const dateTime = new Date(order.fill_tx_ts + 'Z');
          return dateFormater.format(dateTime);
        },
      },
      {
        Header: 'Fill Time',
        id: 'fill_time',
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (!order.fill_tx_ts) {
            return '';
          }

          const dateTime = new Date(order.fill_tx_ts + 'Z');
          return timeFormater.format(dateTime);
        },
      },
      {
        Header: 'Lag Time (ms)',
        id: 'lag_time',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (!order.fill_tx_ts) {
            return '';
          }

          if (!order.trading_pair) {
            return '';
          }

          const isDefiTaking = order.trading_pair.primary_instrument.exchange_type === ExchangeType.DEX;
          return <TradeFeedOrderFillLagTimeDetails isDeFiTaking={isDefiTaking} orderFill={order} />;
        },
      },
      {
        Header: 'Trade ID',
        id: 'trade_id',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.trade_id} placement="top">
              <span className="cursor-pointer" onClick={() => order.trade_id && copyToClipboard(order.trade_id)}>
                {order.trade_id?.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
      },
      {
        Header: 'External ID',
        id: 'external_id',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.external_id} placement="top">
              <span className="cursor-pointer" onClick={() => copyToClipboard(order.external_id)}>
                {order.external_id?.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
      },
      {
        Header: 'Internal ID',
        id: 'internal_id',
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <Tooltip2 content={order.internal_id} placement="top">
              <span className="cursor-pointer" onClick={() => copyToClipboard(order.internal_id)}>
                {order.internal_id?.substr(0, 6)}
              </span>
            </Tooltip2>
          );
        },
      },
      {
        Header: 'Side',
        id: 'side',
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return order.side === 'sell' ? (
            <strong className="text-red-500 uppercase">{order.side}</strong>
          ) : (
            <strong className="text-green-500 uppercase">{order.side}</strong>
          );
        },
      },
      {
        Header: 'Placement',
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (order.placement_logic && order.placement_logic['wick_catcher']) {
            return order.placement_logic['wick_catcher'];
          }

          return '';
        },
      },
      {
        Header: 'Tier',
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          let effective_tier = order.opportunity.effective_tier;

          if (effective_tier) {
            let { type, trigger_market, tier_settings } = effective_tier;

            return (
              <>
                <span className="block">
                  <Tooltip2 content={JSON.stringify(tier_settings)} placement="top">
                    <span>Tier {tier_settings.tier}</span>
                  </Tooltip2>
                </span>

                <span className="block">{trigger_market}</span>

                <span className="block">{type}</span>
              </>
            );
          }

          return '';
        },
      },
      {
        Header: 'Fill Price',
        disableSortBy: true,
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <>
              <span className="block">
                <span className="font-semibold py-1 px-2 border border-gray-300 text-gray-600 rounded-xl whitespace-pre">
                  {order.last_fill_price?.amount}
                </span>
              </span>

              <span className="block mt-2 text-gray-600 text-xxs">{order.last_fill_price?.currency}</span>
            </>
          );
        },
      },
      {
        Header: 'Fill Volume / Total Volume',
        disableSortBy: true,
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          const isFull =
            order.last_fill_volume?.amount &&
            order.original_volume?.amount &&
            order.last_fill_volume?.amount >= order.original_volume?.amount;

          if (isFull) {
            return (
              <>
                <span className="font-semibold py-1 px-2 border border-gray-400 bg-gray-300 text-gray-900 rounded-xl whitespace-pre">
                  {order.last_fill_volume?.amount}
                  {order.original_volume?.amount ? ` / ${order.original_volume?.amount}` : ''}
                </span>

                <span className="block mt-2 text-gray-600 text-xxs">{order.original_volume?.currency}</span>
              </>
            );
          }

          return (
            <>
              <span className="block">
                <span className="font-semibold py-1 px-2 border border-gray-300 text-gray-600 rounded-xl whitespace-pre">
                  {order.last_fill_volume?.amount}
                  {order.original_volume?.amount ? ` / ${order.original_volume?.amount}` : ''}
                </span>
              </span>

              <span className="block mt-2 text-gray-600 text-xxs">{order.last_fill_volume?.currency}</span>
            </>
          );
        },
      },
      {
        Header: 'Fill Value',
        disableSortBy: true,
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          return (
            <>
              <span className="font-semibold py-1 px-2 border border-gray-300 text-gray-600 rounded-xl whitespace-pre">
                {order.last_fill_value?.amount}
              </span>

              <span className="block mt-2 text-gray-600 text-xxs">{order.last_fill_value?.currency}</span>
            </>
          );
        },
      },
      {
        Header: 'Achieved',
        id: 'achieved',
        showOnCompactView: true,
        accessor: (order: TradeFeedOrderFill, _rowIndex: number) => {
          if (!_.isNumber(order.achieved)) {
            return;
          }

          let achieved = order.achieved;

          const profitableSell = order.side === 'sell' && achieved > 0;
          const unProfitableSell = order.side === 'sell' && achieved < 0;
          const profitableBuy = order.side === 'buy' && achieved < 0;
          const unProfitableBuy = order.side === 'buy' && achieved > 0;

          switch (order.trading_pair.strategy_type) {
            case StrategyType.Normal: {
              let achievedText = achieved.toFixed(2) + '%';

              if (profitableSell || profitableBuy) {
                return (
                  <span className="text-green-600 font-bold" title={achieved.toString()}>
                    {achievedText}
                  </span>
                );
              }

              if (unProfitableSell || unProfitableBuy) {
                return (
                  <span className="text-red-500" title={achieved.toString()}>
                    {achievedText}
                  </span>
                );
              }

              return (
                <span className="font-bold" title={achieved.toString()}>
                  {achievedText}
                </span>
              );
            }

            case StrategyType.PairsTrading: {
              let ratioDecimalsCount = order.opportunity.ratio && countDecimalPlaces(order.opportunity.ratio);
              let achievedText = ratioDecimalsCount && achieved.toFixed(ratioDecimalsCount);

              // For PairsTrading, we style Achieved column the same as Side column, not based on profitability
              if (order.side === 'buy') {
                return (
                  <span className="text-green-600 font-bold" title={achieved.toString()}>
                    {achievedText}
                  </span>
                );
              }

              return (
                <span className="text-red-500" title={achieved.toString()}>
                  {achievedText}
                </span>
              );
            }

            case StrategyType.WickCatcher: {
              achieved = Number(achieved.toFixed(2));
              const profitable = achieved > 0;
              const unprofitable = achieved < 0;
              let achievedText = achieved + '%';

              if (profitable) {
                return (
                  <span className="text-green-600 font-bold" title={achieved.toString()}>
                    {achievedText}
                  </span>
                );
              }

              if (unprofitable) {
                return (
                  <span className="text-red-500" title={achieved.toString()}>
                    {achievedText}
                  </span>
                );
              }

              return (
                <span className="font-bold" title={achieved.toString()}>
                  {achievedText}
                </span>
              );
            }
          }
        },
      },
      {
        Header: 'Replenish',
        accessor: 'replenish_id',
      },
      {
        Header: 'Opp ID',
        accessor: 'opportunity_id',
      },
    ];

    if (displayView === TradeFeedDisplayView.CompactView) {
      return supportedColumns.filter((column) => column.showOnCompactView);
    }

    return supportedColumns;
  }, [displayView, dateFormater, timeFormater, softSelectedItem]);

  const fetchData = ({ pageSize, pageIndex, searchFilters, sortBy }: any) => {
    setIsLoadingOrderFills(true);

    let path = `/api/trade_feed/order_fills?page_size=${pageSize}&page=${pageIndex + 1}&sort_by=${buildSortByParams(sortBy)}`;
    if (searchFilters.length > 0) {
      let filterParams: any = {};
      searchFilters
        .filter((fi: SearchFilter) => fi.field !== 'transaction_time')
        .forEach((f: any) => {
          if (f.field === 'hedge_state') {
            filterParams[f.field] = Object.assign({}, f, { value: convertHedgeStateFilterValue(f.value) });
          } else {
            filterParams[f.field] = f;
          }
        });
      path = `${path}&${serialize({ filters: filterParams })}`;

      const dateFilter = searchFilters.find((f: SearchFilter) => f.field == 'transaction_time');
      if (dateFilter) {
        const dateRanges = dateFilter.value.split('>');
        path = `${path}&start_time=${dateRanges[0]}&end_time=${dateRanges[1]}`;
      }
    }

    axios
      .get<TradeFeedOrderFillsListResponse>(path)
      .then((response) => {
        const { entries, total_entries, total_pages } = response.data;
        setPageCount(total_pages);
        setTotalEntries(total_entries);

        const normalizedData = entries.map((order: TradeFeedOrderFill) => Object.assign({}, order, { color: getOrderColor(order) }));
        setData(normalizedData);
        orderService.clearNewFillOrders();
      })
      .finally(() => {
        setIsLoadingOrderFills(false);
      });
  };

  const handleCloseAggregationDialog = () => {
    setShowAggregatedResultsDialog(false);
  };

  const convertHedgeStateFilterValue = (value: string) => {
    switch (value) {
      case 'hedged':
        return 'finished';
      case 'non-hedge':
        return 'failed';
      default:
        return value;
    }
  };

  const buildSortByParams = (sortBy: SortingRule<any>) => {
    if (!sortBy || !sortBy.id) {
      return 'id:desc';
    }

    switch (sortBy.id) {
      case 'fill_time':
        return `transaction_time:${sortBy.desc ? 'desc' : 'asc'}`;
      default:
        return `${sortBy.id}:${sortBy.desc ? 'desc' : 'asc'}`;
    }
  };

  const getOrderColor = (order: TradeFeedOrderFill) => {
    let color = HedgeColor.White;

    if (order.hedge_orders.length === 0) {
      return HedgeColor.Gray;
    }

    const hedgeOrder = order.hedge_orders.sort((o1, o2) => +new Date(o2.init_bot_ts) - +new Date(o1.init_bot_ts))[0];

    if (hedgeOrder.hedge_state === HedgeState.Failed) {
      return HedgeColor.Danger;
    }

    if (hedgeOrder.hedge_state === HedgeState.Finished && orderService.isSweetOrder(order)) {
      return HedgeColor.Green;
    }

    if (hedgeOrder.hedge_state === HedgeState.Finished && orderService.isBlueOrder(order)) {
      return HedgeColor.Blue;
    }

    if (hedgeOrder.hedge_state === HedgeState.Finished && orderService.isUnprofitableOrder(order)) {
      return HedgeColor.Orange;
    }

    return color;
  };

  const addOrUpdateOrderFill = useCallback(
    (orderFill: TradeFeedOrderFill, isAddNewAllowed: boolean) => {
      setData((prevState) => {
        const currentIndex = prevState.findIndex((item) => item.id === orderFill.id);
        const isUpdatedItemShowingOnCurrentPage = currentIndex > -1;

        if (isUpdatedItemShowingOnCurrentPage) {
          // Update existing item with new update
          const newState = [...prevState];
          newState[currentIndex] = orderFill;

          return newState;
        } else {
          if (isAddNewAllowed) return [orderFill, ...prevState];

          return prevState;
        }
      });
    },
    [setData],
  );

  const handleHedging = useCallback(
    (orderFill: TradeFeedOrderFill) => {
      // Only insert new orderFill fill to current page when the current page is page 1 AND
      // strategy_type filter (if set) matches with incoming order fill's
      let isAddNewAllowed;
      let strategyTypeFilter = searchFilters.find((f) => f.field === 'strategy_type');

      if (strategyTypeFilter) {
        isAddNewAllowed = pageIndex === 0 && strategyTypeFilter.value === orderFill.trading_pair.strategy_type;
      } else {
        isAddNewAllowed = pageIndex === 0;
      }

      addOrUpdateOrderFill(orderFill, isAddNewAllowed);
    },
    [addOrUpdateOrderFill, pageIndex, searchFilters],
  );

  const handleRetry = useCallback(
    (order: TradeFeedHedgeOrder, retryState: HedgeState) => {
      setData((prevState) => {
        let currentIndex;
        if (retryState === HedgeState.Failed) {
          currentIndex = prevState.findIndex((item) => order.order_fills && order.order_fills.findIndex((of) => of.id === item.id) >= 0);
        } else {
          currentIndex = prevState.findIndex((item) => item.internal_trade_id === order.primary_internal_trade_id);
        }

        if (currentIndex > -1) {
          const newState = [...prevState];
          order.hedge_state = retryState;
          newState[currentIndex].hedge_orders.push(order);
          return newState;
        }

        return prevState;
      });
    },
    [setData],
  );

  const handleSoftSelectItem = (item: TradeFeedOrderFill | null) => {
    if (!item) {
      setSoftSelectedItem(null);
      return;
    }

    setSoftSelectedItem(item);
  };

  const handleHardSelectItem = (item: TradeFeedOrderFill | null) => {
    if (!item) {
      setHardSelectedItem(null);
      return;
    }

    setHardSelectedItem(item);
  };

  const handleRetryWithRetryingState = useCallback(
    (hedgeOrder: any) => {
      handleRetry(hedgeOrder, HedgeState.Retrying);
    },
    [handleRetry],
  );

  const handleRetryWithFailedState = useCallback(
    (hedgeOrder: any) => {
      handleRetry(hedgeOrder, HedgeState.Failed);
    },
    [handleRetry],
  );

  const handlePrimaryOrderFill = useCallback(
    (orderFill: TradeFeedOrderFill) => {
      // Only insert new orderFill fill to current page when the current page is page 1 AND
      // strategy_type filter (if set) matches with incoming order fill's
      let isAddNewAllowed;
      let strategyTypeFilter = searchFilters.find((f) => f.field === 'strategy_type');

      if (strategyTypeFilter) {
        isAddNewAllowed = pageIndex === 0 && strategyTypeFilter.value === orderFill.trading_pair.strategy_type;
      } else {
        isAddNewAllowed = pageIndex === 0;
      }

      addOrUpdateOrderFill(orderFill, isAddNewAllowed);
    },
    [addOrUpdateOrderFill, pageIndex, searchFilters],
  );

  useEffect(() => {
    orderService.hedgeEvent.on('primary:order_fill:init', handlePrimaryOrderFill);
    orderService.hedgeEvent.on('primary:order_fill:update', handlePrimaryOrderFill);

    orderService.hedgeEvent.on('hedging:order:init', handleHedging);
    orderService.hedgeEvent.on('hedging:order:update', handleHedging);
    orderService.hedgeEvent.on('hedging:order:ws_update', handleHedging);

    orderService.hedgeEvent.on('retry:init', handleRetryWithRetryingState);
    orderService.hedgeEvent.on('retry:hedging', handleRetryWithRetryingState);
    orderService.hedgeEvent.on('retry:failed_and_exceeded_max_retries', handleRetryWithFailedState);
    orderService.hedgeEvent.on('retry:failed_with_non_retryable_error', handleRetryWithFailedState);

    return () => {
      orderService.hedgeEvent.off('primary:order_fill:init', handlePrimaryOrderFill);
      orderService.hedgeEvent.off('primary:order_fill:update', handlePrimaryOrderFill);

      orderService.hedgeEvent.off('hedging:order:init', handleHedging);
      orderService.hedgeEvent.off('hedging:order:update', handleHedging);
      orderService.hedgeEvent.off('hedging:order:ws_update', handleHedging);

      orderService.hedgeEvent.off('retry:init', handleRetryWithRetryingState);
      orderService.hedgeEvent.off('retry:hedging', handleRetryWithRetryingState);
      orderService.hedgeEvent.off('retry:failed_and_exceeded_max_retries', handleRetryWithFailedState);
      orderService.hedgeEvent.off('retry:failed_with_non_retryable_error', handleRetryWithFailedState);
    };
  }, [handlePrimaryOrderFill]);

  // Press ESC key to cancel softSelectedItem
  const hotkeys = useMemo(
    () => [
      {
        combo: 'esc',
        global: true,
        label: null,
        onKeyDown: () => handleSoftSelectItem(null),
        preventDefault: true,
      },
    ],
    [],
  );
  const { handleKeyDown } = useHotkeys(hotkeys);

  return (
    <>
      <div className="trade-feed-container w-full" onKeyDown={handleKeyDown}>
        <div className="" onClick={(e) => e.stopPropagation()}>
          <h2 className="text-lg font-bold my-1 mr-5 leading-tight">Trade Feed</h2>

          <div className="mt-3">
            {searchFilters && (
              <TradeFeedSearchTool
                filters={searchFilters}
                setFilters={setSearchFilters}
                onSearch={handleSearch}
                onFetchAggregation={handleShowAggregatedResults}
              />
            )}
          </div>
        </div>

        <Drawer
          isOpen={!!hardSelectedItem}
          onClose={() => {
            setHardSelectedItem(null);
          }}
          title={
            <>
              <span>
                #{hardSelectedItem?.id} Order Fill (
                {hardSelectedItem && getPercentageOrRatioText(hardSelectedItem.trading_pair, hardSelectedItem)})
              </span>

              <span className="mx-3">|</span>

              <span>
                <Link
                  to={`/bots/${hardSelectedItem?.trading_pair.bot?.app_id}/trading_pairs/${hardSelectedItem?.trading_pair_id}`}
                  className="text-gray-800"
                >
                  #{hardSelectedItem?.trading_pair.id} {hardSelectedItem?.trading_pair.name}{' '}
                </Link>
              </span>

              <span className="mx-3">|</span>

              <span>
                <Link to={`/bots/${hardSelectedItem?.trading_pair.bot?.app_id}/trading_pairs`} className="text-gray-800">
                  {hardSelectedItem?.trading_pair.bot?.name}
                </Link>
              </span>
            </>
          }
          canEscapeKeyClose={true}
          size="90%"
        >
          <div className={Classes.DRAWER_BODY}>
            <div className={Classes.DIALOG_BODY}>{hardSelectedItem && <TradeFeedOrderFillItem item={hardSelectedItem} />}</div>
          </div>
        </Drawer>

        <Dialog
          portalContainer={document.getElementById('app') || undefined}
          title={'Aggregated Results'}
          transitionDuration={500}
          isOpen={showAggregatedResultsDialog}
          onClose={handleCloseAggregationDialog}
          style={{ width: 1150 }}
        >
          <div className={Classes.DIALOG_BODY}>
            <TradeFeedResultsAggregatedList filters={searchFilters} />
          </div>
        </Dialog>

        {sortBy ? (
          <TradeFeedListLatest
            columns={columns}
            data={data}
            displayView={displayView}
            changeDisplayView={setDisplayView}
            initSortBy={[sortBy]}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalPage={pageCount}
            totalEntries={totalEntries}
            onChangePage={handlePageChange}
            softSelectedItem={softSelectedItem}
            softSelectItem={handleSoftSelectItem}
            hardSelectItem={handleHardSelectItem}
            showPagination={true}
            showCounter={false}
            showColor={true}
            isLoading={isLoadingOrderFills}
          />
        ) : null}
      </div>
    </>
  );
};
