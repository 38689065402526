import { Route, Routes } from 'react-router-dom';

import { InstrumentEditPage } from '../../pages/instruments/edit';
import { InstrumentNewPage } from '../../pages/instruments/new';
import { InstrumentsIndexPage } from '../../pages/instruments';
import { LeftSidebarWithoutNavLayout } from '../layout/left-sidebar-without-nav';

export function InstrumentsRoutes() {
  return (
    <LeftSidebarWithoutNavLayout>
      <Routes>
        <Route path="/new" element={<InstrumentNewPage />} />
        <Route path=":id/edit" element={<InstrumentEditPage />} />
        <Route path="/" element={<InstrumentsIndexPage />} />
      </Routes>
    </LeftSidebarWithoutNavLayout>
  );
}
