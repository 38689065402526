import { useContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import { AuthContext } from '../../contexts/auth';
import { BotsSettingsPage } from '../../pages/bots/settings';
import { LeftSidebarLayout } from '../layout/left-sidebar';

export function BotSettingsRoutes() {
  // path refers to the parent route
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    return <></>;
  }

  return (
    <LeftSidebarLayout>
      <Routes>
        <Route index element={<BotsSettingsPage />} />
      </Routes>
    </LeftSidebarLayout>
  );
}
