import { Route, Routes } from 'react-router-dom';

import { TradingPairsNewPage } from '../../pages/trading_pairs/new';
import { TradingPairsShowPage } from '../../pages/trading_pairs/show';
import { TradingPairsIndexPage } from '../../pages/trading_pairs';
import { LeftSidebarLayout } from '../layout/left-sidebar';

export function TradingPairsRoutes() {
  return (
    <LeftSidebarLayout>
      <Routes>
        <Route path="new" element={<TradingPairsNewPage />} />
        <Route path="active" element={<TradingPairsIndexPage />} />
        <Route path="recently_active" element={<TradingPairsIndexPage />} />
        <Route path="recently_created" element={<TradingPairsIndexPage />} />
        <Route path=":id" element={<TradingPairsShowPage />} />
        <Route index element={<TradingPairsIndexPage />} />
      </Routes>
    </LeftSidebarLayout>
  );
}
