import { Route, Routes } from 'react-router-dom';

import { DexList } from '../../components/dex/list/list';
import { LeftSidebarWithoutNavLayout } from '../layout/left-sidebar-without-nav';

export function DexesRoutes() {
  return (
    <LeftSidebarWithoutNavLayout>
      <Routes>
        <Route path="/" element={<DexList />} />
      </Routes>
    </LeftSidebarWithoutNavLayout>
  );
}
