import { Route, Routes } from 'react-router-dom';

import { AccountEditPage } from '../../pages/accounts/edit';
import { AccountNewPage } from '../../pages/accounts/new';
import { AccountsIndexPage } from '../../pages/accounts';
import { LeftSidebarWithoutNavLayout } from '../layout/left-sidebar-without-nav';

export function AccountsRoutes() {
  return (
    <LeftSidebarWithoutNavLayout>
      <Routes>
        <Route path="/new" element={<AccountNewPage />} />
        <Route path=":id/edit" element={<AccountEditPage />} />
        <Route path="/" element={<AccountsIndexPage />} />
      </Routes>
    </LeftSidebarWithoutNavLayout>
  );
}
