import { Dialog, Classes, Button, Intent, ButtonGroup, FormGroup } from '@blueprintjs/core';
import { useState } from 'react';

import axios from '../../shared/custom-axios';
import { AccountSelect } from '../common/account-select/account-select';
import { Account } from '../../shared/interfaces/bot';
import { AppToaster } from '../../shared/app-toaster';

interface Props {
  fill?: boolean;
  small?: boolean;
}

export function CancelAllButton(props: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState<Account | 'all' | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCancelAll = () => {
    if (!selectedAccount) return;

    setIsLoading(true);

    if (selectedAccount === 'all') {
      const path = `/api/bots/cancel_all`;

      axios
        .post<any>(path, {})
        .then((response: any) => {
          const message = response.data.message;

          setIsVisible(false);
          setSelectedAccount(null);

          AppToaster.show({ message: message, icon: 'tick', intent: Intent.WARNING, timeout: 10000 });
        })
        .catch((error) => {
          const message = JSON.stringify(error.response.message);
          AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
        })
        .finally(() => setIsLoading(false));

      return;
    }

    const path = `/api/bots/cancel_all_orders`;

    axios
      .post<any>(path, { account_id: selectedAccount?.id, account_name: selectedAccount?.name })
      .then((response: any) => {
        const message = response.data.message;

        setIsVisible(false);
        setSelectedAccount(null);

        AppToaster.show({ message: message, icon: 'tick', intent: Intent.WARNING, timeout: 10000 });
      })
      .catch((error) => {
        const message = JSON.stringify(error.response.message);
        AppToaster.show({ message: message, icon: 'warning-sign', intent: Intent.DANGER, timeout: 3500 });
      })
      .finally(() => setIsLoading(false));
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <div>
      <ButtonGroup className="mr-3" fill={props.fill}>
        <Button
          icon="stop"
          onClick={() => {
            setIsVisible(!isVisible);
          }}
          intent={Intent.DANGER}
          outlined={true}
          small={props.small}
        >
          Cancel All
        </Button>
      </ButtonGroup>

      <Dialog
        portalContainer={document.getElementById('app') || undefined}
        title="Cancel all orders for an account"
        transitionDuration={500}
        isOpen={isVisible}
        onClose={handleClose}
        style={{ minWidth: '600px' }}
      >
        <div className={Classes.DIALOG_BODY}>
          <div>
            <FormGroup label="Account to cancel:" labelInfo="">
              <AccountSelect
                selectedItem={selectedAccount}
                handleSelect={(account: Account | 'all') => {
                  setSelectedAccount(account);
                }}
              />
            </FormGroup>

            <div className="mt-7 text-right">
              <Button onClick={handleClose}>Close</Button>

              <Button loading={isLoading} intent={Intent.WARNING} className="ml-3" onClick={handleCancelAll}>
                Cancel All
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
