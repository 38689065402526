import { Route, Routes } from 'react-router-dom';

import { OrderBookList } from '../../components/order-book/list/list';
import { OrderBookForm } from '../../components/order-book/form/form';
import { LeftSidebarLayout } from '../layout/left-sidebar';

export function OrderBooksRoutes() {
  return (
    <LeftSidebarLayout>
      <Routes>
        <Route path="/new" element={<OrderBookForm />} />
        <Route path="/" element={<OrderBookList />} />
      </Routes>
    </LeftSidebarLayout>
  );
}
