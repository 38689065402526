import { Route, Routes } from 'react-router-dom';

import { BotsMetricsPage } from '../../pages/bots/metrics';
import { LeftSidebarWithoutNavLayout } from '../layout/left-sidebar-without-nav';

export function MetricsRoutes() {
  return (
    <LeftSidebarWithoutNavLayout>
      <Routes>
        <Route path="" element={<BotsMetricsPage />} />
      </Routes>
    </LeftSidebarWithoutNavLayout>
  );
}
