import { Dialog, Classes } from '@blueprintjs/core';
import { WickCatcher } from '../../../shared/interfaces/bot';

import { WickCatcherForm } from '../form/form';
import styles from './edit-dialog.module.css';

interface Props {
  item: WickCatcher | null;
  setItem: (item: WickCatcher | null) => void;
  onReloadItem: (itemId: number) => void;
}

export function WickCatcherEditDialog({ item, setItem, onReloadItem }: Props) {
  return (
    <Dialog
      portalContainer={document.getElementById('app') || undefined}
      title={`${item?.name}`}
      transitionDuration={500}
      isOpen={!!item}
      onClose={() => {
        setItem(null);
      }}
      className={styles.dialog}
      style={{ minWidth: '960px', width: '80%', minHeight: '100vh' }}
    >
      <div className={Classes.DIALOG_BODY}>
        <WickCatcherForm item={item} setItem={setItem} onReloadItem={onReloadItem} />
      </div>

      <div className={Classes.DIALOG_FOOTER}></div>
    </Dialog>
  );
}
