import { useContext } from 'react';

import { AuthContext } from '../../contexts/auth';

import { UserProfile } from '../../components/user/profile/profile';
import { LeftSidebarLayout } from '../layout/left-sidebar';

export function UserRoutes() {
  // path refers to the parent route
  const { currentUser } = useContext(AuthContext);

  if (!currentUser) {
    return <></>;
  }

  return (
    <LeftSidebarLayout>
      <div className="mt-10 w-4/12 m-auto">
        <UserProfile user={currentUser}></UserProfile>
      </div>
    </LeftSidebarLayout>
  );
}
