import { Footer } from '../../components/footer/footer';
import Sidebar from '../../components/sidebar/sidebar';

export function LeftSidebarWithoutNavLayout({ children }: any) {
  return (
    <>
      <div className="App-header"></div>

      <div className="App-body h-full">
        <div className="flex flex-col min-h-screen">
          <div className="flex flex-1">
            <Sidebar />

            <main className="flex-1 pl-56 pr-5 mt-3 w-screen min-h-screen z-0">{children}</main>
          </div>
        </div>
      </div>

      <div className="App-footer fixed z-100 bottom-0 left-0 right-0 text-sm">
        <Footer />
      </div>
    </>
  );
}
